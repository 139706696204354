import jquery from 'jquery';
window.$ = window.jQuery=jquery
import 'slick-carousel';

$(document).ready(function() {
	// bio images
	$('.container_bio--cols a').hover(function() {
		var image = $(this).data('img');
		var position = $(this).data('position');
		$(this).append('<img class="bio-img bio-img__' + position + '" src="' + image + '" />');
	}, function() {
		$('.container_bio--cols .bio-img').remove();
	});

	// performance title
	$('.container__performances .project_container--main img').hover(
		function() {
			$('.project_container--main h2').toggle();
		}
	);

	// performance inside
	$('.container__performances .project_container--main img').click(function() {
		$('body').removeClass('body--projects--non-overflow');
		$('.project_container--main').hide();
		$('.container__projects--inside .back').css({'position': 'relative', 'bottom': '0px', 'float': 'right', 'right': '20px'});
		$('.project_container--inside').css('display', 'flex');
		$('.project_container--inside .project-carousel').slick({
			autoplay: true,
			arrows: false
		});
	});

	// video arts
	$('.container__videoarts .project_container--inside .project-carousel').slick({
		autoplay: true,
		arrows: false
	});

	// commissions
	$('.container__commissions .project_container--inside .project-carousel').slick({
		autoplay: true,
		arrows: false
	});

	$('.container__sound img').click(function() {
		// show text
		$('.container__sound .project_container--inside--right').removeClass('hidden');

		// change text height
		var colHeight = $('.project_container--inside--right').height();
		if (window.innerHeight > colHeight) {
			$('.project_container--inside--right').css('height', '100vh');
		}

		// scroll to text
		if (window.innerWidth <= 1200) {
			$('html, body').animate({
				scrollTop: $('.project_container--inside--right').offset().top
			}, 1000);
		}
	});

	if ($('.project_compilation').length) {
		var colHeight = $('.project_container--inside--right').height();
		if (window.innerHeight > colHeight) {
			$('.project_container--inside--right').css('height', '100vh');
		}
	};
});
